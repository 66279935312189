import React from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import srraf from 'srraf'

import store from '../state/store.js'
import { renderModules } from '@/utls.js'
import { Projects } from '@/svgs.js'
import Footer from '@/footer.js'
import META from '@/meta.js'



const updateStates = () => {
  store.hydrate({
    projectNav: !store.state.projectNav
  })()
  const html = document.querySelector('html')
  html.classList.add('opened')
}



class Portfolio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: props.pageContext.data.password || null,
      answer: '',
      isHidden: false,
      fixOpen: false,
      top: 120
    }
    this.scroller = null
    this.scrolling = null
    this.form = React.createRef()
    this.bindScroll = this.bindScroll.bind(this)
    this.description = React.createRef()
  }
  componentDidMount () {
    const dots = document.querySelectorAll('.js-cats')
    this.bindScroll()
    this.setState({
      top: 78 + (20 * dots.length)
    })
  }
  bindScroll() {
    if (!this.scroller && this.state.fixOpen) {
      this.scroller = srraf(({ y }, timestamp) => {
        this.setState({
          fixOpen: false
        })
      })
    }
  }
  handlePassword (e) {
    e.preventDefault()
    const form = this.form.current
    const {password} = form.elements
    this.setState({
      answer: password.value
    })
  }
  handleFixedDesc () {
    this.setState({
      fixOpen: !this.state.fixOpen
    })
    setTimeout(() => {
      this.bindScroll()
    }, 40)
  }

  componentWillReceiveProps() {
    this.bindScroll()
  }
  componentWillUnmount() {
    if (this.scroller) {
      this.scroller.destroy()
    }
    if (this.scrolling) {
      this.scrolling.destroy()
    }
  }
  render () {
    const {
      password,
      answer
    } = this.state
    const {
      transitionStatus
    } = this.props
    const {
      clientName,
      description,
      modules,
      categoryLength,
      textBlocks,
      slug,
      portfolioTitle
    } = this.props.pageContext.data
    return (
      <div className={cx('animate__page', transitionStatus)}>
        <div className='nav__push' />
        {password && password !== answer ? (
          <div className='container--xl p15 ma'>
            <div className='project__password f jcc aic ac'>
              <div>
                <h3>This requires a password</h3>
                <form onSubmit={e => this.handlePassword(e)} ref={this.form}>
                  <input name='password' type='password' className='p1 ac' placeholder='password' />
                </form>
                {answer && answer !== password && (
                  <span className='error block mt05'>Invalid password</span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="projectsContain">
            <META data={this.props.pageContext.data} url={`projects/${slug.current}`} />
            <div className='projects portfolioCaseStudies'>
              <div className='container--xl p15 simple-xl ma x pt0 py0'>
                <div className='project__content rel'>
                  <div className='project__nav' onClick={updateStates} >
                    <Projects className=''/>
                  </div>
                  <div className='f jcs aic project__nav-title work__landing-nav' onClick={updateStates}>
                    <h5 className='p0 mono  caps m0'>Projects</h5>
                  </div>
                  <div className='ma x f jcb aic fw mt2'>
                    <h1 className='light mb0 mt0 pt05 pb0'>
                      {portfolioTitle}
                    </h1>
                  </div>
                  <div className='x f jcb aic fw portfolioCopy'>
                    <p className='mt05'><BlockContent blocks={description} /></p>
                  </div>
                  {renderModules(modules, categoryLength)}
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    )
  }
}

export default Portfolio
